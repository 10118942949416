import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './components/HomePage';
import ProfileForm from './components/ProfileForm';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';

const App = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={isAuthenticated ? <ProfileForm /> : <HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<ProtectedRoute component={ProfileForm} />} />
            </Routes>
        </Router>
    );
};

export default App;
