import React, { useState, useEffect } from 'react';

const ProfileDisplay = ({ profile }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay for loading
        const timer = setTimeout(() => setLoading(false), 3000); // Adjust this time as needed
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div className="loading-overlay">
                <div className="loading-spinner"></div>
                <div className="loading-text">Loading profile... Please wait</div>
            </div>
        );
    }

    const { name, job_role, pic, p_headliner, p_tags, p_traits, DISC_Profile } = profile;
    const tags = p_tags.split(',').map(tag => tag.trim());
    const initials = name.split(' ').map(n => n[0]).join('').slice(0, 2); // Extract a maximum of two initials

    if (!DISC_Profile) {
        return <div>Invalid DISC Profile data</div>;
    }

    const renderTraitLine = (value, lowLabel, highLabel) => (
        <div className="trait-line-container">
            <div className="trait-end-label">{lowLabel}</div>
            <div className="trait-line-wrapper">
                <div className="trait-line">
                    <div className="trait-dot" style={{ left: `${value}%` }}>
                        <div className="initials">{initials}</div>
                    </div>
                </div>
            </div>
            <div className="trait-end-label">{highLabel}</div>
        </div>
    );

    return (
        <div className="profile-container">
            {/* Card 1 */}
            <div className="card profile-card">
                <img src={pic} alt={`${name}'s Profile`} className="profile-picture" />
                <h2>{name}</h2>
                <h3>{job_role}</h3>
                <p><strong>{p_headliner}</strong></p>
                <div className="tag-container">
                    {tags.map((tag, index) => (
                        <span key={index} className="tag-bubble">{tag}</span>
                    ))}
                </div>
            </div>

            {/* Card 2 */}
            <div className="card personality-summary-card">
                <h3 className="card-heading">{`${name.split(' ')[0]}'s Personality`}</h3>
                <p><strong>DISC Role:</strong> {DISC_Profile.disc_role}</p>
                <p>{DISC_Profile.p_summary}</p>
                <div className="bar-chart">
                    <div className="bar-label">Dominance</div>
                    <div className="bar bar-dominance" style={{ width: DISC_Profile.Dominance.level }}>
                        {DISC_Profile.Dominance.level}
                    </div>

                    <div className="bar-label">Influence</div>
                    <div className="bar bar-influence" style={{ width: DISC_Profile.Influence.level }}>
                        {DISC_Profile.Influence.level}
                    </div>

                    <div className="bar-label">Steadiness</div>
                    <div className="bar bar-steadiness" style={{ width: DISC_Profile.Steadiness.level }}>
                        {DISC_Profile.Steadiness.level}
                    </div>

                    <div className="bar-label">Compliance</div>
                    <div className="bar bar-compliance" style={{ width: DISC_Profile.Compliance.level }}>
                        {DISC_Profile.Compliance.level}
                    </div>
                </div>
            </div>

            {/* Card 3 - Personality Traits */}
            <div className="card">
                <h3 className="card-heading">Personality Traits</h3>
                {renderTraitLine(p_traits.risk, 'Risk Tolerant', 'Risk Averse')}
                {renderTraitLine(p_traits.trust, 'Trusting', 'Skeptical')}
                {renderTraitLine(p_traits.optimism, 'Optimistic', 'Pragmatic')}
                {renderTraitLine(p_traits.deliberate, 'Deliberate', 'Fast-Paced')}
                {renderTraitLine(p_traits['matter-of-fact'], 'Matter-of-Fact', 'Expressive')}
                {renderTraitLine(p_traits.autonomy, 'Autonomous', 'Collaborative')}
                {renderTraitLine(p_traits.supporting, 'Supportive', 'Dominant')}
            </div>

            {/* Card 4 - Dominance */}
            <div className="card">
                <h3 className="card-heading">Dominance</h3>
                <p><strong>Level:</strong> {DISC_Profile.Dominance.level}</p>
                <p>{DISC_Profile.Dominance.description}</p>
            </div>

            {/* Card 5 - Influence */}
            <div className="card">
                <h3 className="card-heading">Influence</h3>
                <p><strong>Level:</strong> {DISC_Profile.Influence.level}</p>
                <p>{DISC_Profile.Influence.description}</p>
            </div>

            {/* Card 6 - Steadiness */}
            <div className="card">
                <h3 className="card-heading">Steadiness</h3>
                <p><strong>Level:</strong> {DISC_Profile.Steadiness.level}</p>
                <p>{DISC_Profile.Steadiness.description}</p>
            </div>

            {/* Card 7 - Compliance */}
            <div className="card">
                <h3 className="card-heading">Compliance</h3>
                <p><strong>Level:</strong> {DISC_Profile.Compliance.level}</p>
                <p>{DISC_Profile.Compliance.description}</p>
            </div>
        </div>
    );
};

export default ProfileDisplay;
