import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const HomePage = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div>
            <h1>Welcome to the DISC Profile App</h1>
            <p>Please log in to view and submit profiles.</p>
            <button onClick={() => loginWithRedirect()}>Log In</button>
        </div>
    );
};

export default HomePage;
