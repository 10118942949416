import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
    const { loginWithRedirect } = useAuth0();
    const [email, setEmail] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await loginWithRedirect({
                connection: 'email', // This tells Auth0 to use the passwordless email connection
                email,
            });
        } catch (error) {
            console.error('Error logging in with magic link:', error);
        }
    };

    return (
        <div className="container">
            <h2>Login with Magic Link</h2>
            <form onSubmit={handleLogin}>
                <label>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">Send Magic Link</button>
            </form>
        </div>
    );
};

export default Login;
