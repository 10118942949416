import React, { useState } from 'react';
import axios from 'axios';
import ProfileDisplay from './ProfileDisplay';
import { useAuth0 } from '@auth0/auth0-react';

const ProfileForm = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const [username, setUsername] = useState('');
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchProfile = async () => {
        setLoading(true);
        setError(null);
        try {
            // Get the access token
            const token = await getAccessTokenSilently();

            const response = await axios.post(apiUrl, 
              { username }, 
              {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Received profile data:', response.data);
            const profileData = response.data.discProfile.message.content;
            if (profileData) {
                setProfile(profileData);
            } else {
                setError('Invalid DISC profile data received');
            }
        } catch (err) {
            setError(`Failed to fetch profile: ${err.message}`);
            console.error('Fetch profile error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username) {
            fetchProfile();
        } else {
            setError('Please provide a LinkedIn username.');
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <label>
                    Provide LinkedIn username:
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
                <button type="submit">Submit</button>
            </form>
            <button onClick={() => logout({ returnTo: window.location.origin })}>
                Log Out
            </button>
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                    <div className="loading-text">Loading profile... Please wait</div>
                </div>
            )}
            {error && <p>{error}</p>}
            {profile && !loading && <ProfileDisplay profile={profile} />}
        </div>
    );
};

export default ProfileForm;
